/**
 * Hills Pet Registration Form
 */

(($) => {
  const api = {};

  const onSuccess = (response) => {
    if (response && response.status === 'OK') {
      let target = window.location.href;
      const index = target.lastIndexOf('/home/');
      if (index > 0) {
        target = target.substring(0, index + '/home'.length).concat('.html');
      } else {
        target = '/';
      }
      window.location.replace(target);
    } else {
      window.location.reload();
    }
  };

  const onFormSubmit = $form => (
    (event) => {
      event.preventDefault();
      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: $form.serialize(),
        success: onSuccess,
      });
    }
  );

  api.onRegister = (scope) => {
    const $form = scope.$scope.find('form').first();
    api.external.formValidator.setFormValidation($form);
    $form.on('submit', onFormSubmit($form));
  };

  Cog.registerComponent({
    name: 'hillsPetRegistrationForm',
    api,
    requires: [
      {
        name: 'utils.status',
        apiId: 'status',
      },
      {
        name: 'utils.formValidator',
        apiId: 'formValidator',
      },
    ],
    selector: '.hills-pet-registration-form',
  });
})(Cog.jQuery());
