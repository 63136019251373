/**
 * FormValidator - Helper to validate inputs in complex forms.
 */
(($) => {
  const api = {};
  const sharedApi = {};
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

  const addErrorMessages = ($element) => {
    const requiredErrorMessage = $element.find('.component-content').data('required-error-message');
    const invalidErrorMessage = $element.find('.component-content').data('invalid-value-error-message');
    const requiredMessageElement = `<div class="error-message-invalid">${requiredErrorMessage}</div>`;
    const invalidMessageElement = `<div class="error-message-required">${invalidErrorMessage}</div>`;

    $element
      .find('.controls')
      .append(requiredMessageElement)
      .append(invalidMessageElement);
  };

  const validatePasswordField = ($element) => {
    const passwordFieldValue = $element
      .parents('.formContent')
      .find('input[name="password"]')
      .val();

    const confirmPasswordFieldValue = $element.find('input').val();

    return passwordFieldValue === confirmPasswordFieldValue;
  };

  const validateEmailField = $element => (
    emailRegex.test($element.find('input').val())
  );

  const setValidationStatus = ($elementControls, validationStatus) => {
    if (validationStatus) {
      $elementControls.removeClass('error-invalid');
    } else {
      $elementControls.addClass('error-invalid');
    }
  };

  const checkFieldValidity = ($element) => {
    const inputField = $element.find('input');

    const elementValidity = inputField
      .first()[0]
      .validity;

    const $elementControls = $element.find('.controls');
    const isConfirmPassword = inputField.attr('name') === 'confirmPassword';
    const isEmail = inputField.attr('name') === 'emailAddress';
    let isElementEmpty = false;

    if (
      elementValidity.valueMissing
    ) {
      $elementControls.addClass('error-required');
      isElementEmpty = true;
    } else {
      $elementControls.removeClass('error-required');
    }

    if (
      elementValidity.typeMismatch ||
      elementValidity.patternMismatch
    ) {
      $elementControls.addClass('error-invalid');
    } else {
      $elementControls.removeClass('error-invalid');
    }

    if (!isElementEmpty && isEmail) {
      setValidationStatus($elementControls, validateEmailField($element));
    }

    if (!isElementEmpty && isConfirmPassword) {
      setValidationStatus($elementControls, validatePasswordField($element));
    }
  };

  const setButtonBlurState = ($button) => {
    $button
      .parents('.formContent')
      .find('.textField')
      .each((index, element) => {
        checkFieldValidity($(element));
      });

    const invalidElements = $button
      .parents('.formContent')
      .find('.controls')
      .filter((index, elem) => (
        $(elem).hasClass('error-invalid') || $(elem).hasClass('error-required')
      ));

    return invalidElements.length > 0;
  };

  const attachEventHandler = ($element) => {
    $element
      .find('input')
      .on('focusout', () => {
        checkFieldValidity($element);
      });
  };

  sharedApi.setFormValidation = ($scope) => {
    $scope
      .find('.textField')
      .each((index, element) => {
        addErrorMessages($(element));
        attachEventHandler($(element));
      });

    $scope
      .find('.formButton button')
      .on('click', (event) => {
        if (setButtonBlurState($(event.target))) {
          event.preventDefault();
          event.stopPropagation();
        }
      });
  };


  Cog.registerStatic({
    name: 'utils.formValidator',
    api,
    sharedApi,
  });
})(Cog.jQuery());
