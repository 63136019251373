/**
 * Juicer (social feed) component
 */

(($) => {
  const api = {};
  const $window = $(window);
  const isAuthor = $('body').is('.cq-wcm-edit, .cq-wcm-edit-touch');
  const scrollDelay = 50;
  const $head = $('head');
  let juicerLoaded = false;

  const startJuicer = () => {
    if (typeof Juicer === 'object' && typeof Juicer.initialize === 'function') {
      Juicer.initialize();
    }
  };

  const checkIfVisible = ($juicer) => {
    const juicerFeedScrollDistance = $juicer.offset().top;
    const scrollDistance = $window.scrollTop();
    const windowHeight = $window.height();
    const offset = juicerFeedScrollDistance - windowHeight;

    if (((scrollDistance >= offset) && !juicerLoaded) || (isAuthor && !juicerLoaded)) {
      $.getScript('//assets.juicer.io/embed-amd.js', startJuicer);

      $head.append('<link rel="stylesheet" type="text/css" href="//assets.juicer.io/embed.css">');
      juicerLoaded = true;
    }
  };

  api.onRegister = (scope) => {
    const $juicer = scope.$scope;

    startJuicer();
    checkIfVisible($juicer);

    $window.on('scroll', _.debounce(() => {
      checkIfVisible($juicer);
    }, scrollDelay));
  };

  Cog.registerComponent({
    name: 'juicer',
    api,
    selector: '.juicer-feed',
  });
})(Cog.jQuery());
